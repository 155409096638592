import {BASE_URL,DEFAULT_KEY} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';
//a201cb4c-0fa2-e8bae44e-aee1-3bfceb9a5fc5

export default class AppInfoservice{
    key =localStorage.getItem('apiKey')
    async Appinfo(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'common/app_info', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key?this.key:DEFAULT_KEY   // API key
                },
                // body: JSON.stringify(userData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    check_token(KeyData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'common/check_token', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key   // API key
                },
                body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    get_layout(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/app_layout', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,   // API key
                    'access-token'  : UserStore.key
                },
                // body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    //get the notification 
    async getNotification(){
        const response = await fetch(BASE_URL+'home/notifications', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const newsData = await response.json();
        return newsData ;
    }

    async getCountry(){
        const response = await fetch(`${BASE_URL}common/getAllCountry`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json()
        return result;
    }
    
    async getStuff(){
        const response = await fetch(`${BASE_URL}crm/lead_form`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json()
        return result;
    }



}