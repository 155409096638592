
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://demo4.infinitemlmdemo.com/david_jarman/backoffice/api/";
// const IMG_URL = "https://demo4.infinitemlmdemo.com/david_jarman/uploads/images/";
// const BASE = "https://demo4.infinitemlmdemo.com/david_jarman/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '2a6bf5ed-178e-4d2f-800c-76022261530b'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }


// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://demo8.infinitemlmdemo.com/david_jarman/backoffice/api/";
// const IMG_URL = "http://demo8.infinitemlmdemo.com/david_jarman/uploads/images/";
// const BASE = "http://demo8.infinitemlmdemo.com/david_jarman/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '2a6bf5ed-178e-4d2f-800c-76022261530b'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }



// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.12.89/WC/david_jarman/backoffice/api/";
// const IMG_URL = "http://192.168.12.89/WC/david_jarman/uploads/images/";
// const BASE = "http://demo8.infinitemlmdemo.com/david_jarman/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '2a6bf5ed-178e-4d2f-800c-76022261530b'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://shop.lifetimequote.com/backoffice/api/";
const IMG_URL = "https://shop.lifetimequote.com/uploads/images/";
const BASE = "https://shop.lifetimequote.com/";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY = '2a6bf5ed-178e-4d2f-800c-76022261530b'
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY
}

