import React, { useCallback, useEffect, useState } from 'react';
import style from './dashboard.module.scss';
import FormsControl from './FormsControl';
import { DatePickers, FormInputControl, DropDownBs, CustomButton, AlertBs } from '../common'
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CrmService from '../../../service/crm/Crm';



const appli_status = [
    {
        id: 'Quote'
    },
    {
        id: 'Apply'
    },
    {
        id: 'Pending'
    },
    {
        id: 'Issued'
    },
    {
        id: 'Decline'
    }
]

const InitialBusinessForm = {
    update_id: '',
    needtoclean: false,
    lead_id: '',
    carrier: '',
    policy: '',
    application_status: '',
    product_line: '',
    // description : '',
    formError: {
        lead_id: '',
        carrier: '',
        policy: '',
        application_status: '',
        product_line: '',
        // description : '',
    }

}



const InitialRelationForm = {
    relationship: '',
    formErrorRelation: {
        relationship: '',
    }
}

function EditnAddBusinessHere(props) {
    const { t } = useTranslation();
    const params = useParams();
    const history = useHistory();
    const columns = [
        {
            // name: t('Common.name'),
            name: '#',
            cell: row => `${row.id}`
        },
        {
            name: 'Carrier name',
            cell: row => `${row.carrier}`
        },
        {
            name: 'Policy number',
            cell: row => `${row.policy}`
        },
        {
            name: 'Product name',
            cell: row => `${row.product_line}`
        },
        {
            name: 'Application status',
            cell: row => `${row.application_status}`
        },
        // {
        //     name: 'Relationship',
        //     cell: row => `${row.lead}`
        // },
        // {
        //     name: t('Common.emai'),
        //     selector: 'email',
        //     sortable: true,
        //     right: true,
        // },

        // {
        //     name: t('Common.phone'),
        //     selector: 'phone',
        //     sortable: true,
        //     right: true,
        // },

        {
            name: t('Common.action'),
            // cell: row => <Button onClick={()=>props.editClick(row.id)}>{t('Button.edit')}</Button>
            cell: row => <Button onClick={() => EditClickAgain(`${row.id}`, `${row.carrier}`, `${row.policy}`, `${row.application_status}`, `${row.product_line}`)}>{t('Button.edit')}</Button>
            // cell: row => <Button>{t('Button.edit')}</Button>
        },
    ];


    const relationColumns = [
        {
            name: 'Relationship',
            cell: row => `${row.lead_name}`
        },
        {
            name: t('Common.action'),
            cell: row => <Button onClick={() => DelShit(`${row.id}`)}>{t('Button.del')}</Button>
        },
    ];

    const [state, setState] = useState({
        ...InitialBusinessForm,
        ...InitialRelationForm,
        leadsyo: '',
        relationyo: '',
        submitTypehere: 'submit'
    })

    const [yocolour,setColour] = useState({
        bg : "#fffc",
        herecolor : "#656565",
        buttonbg : "#7266ba",
        buttoncolor : "#fff"
    })

    const [seemsg, setMsg] = useState({
        alert: {
            show: false,
            message: '',
            varient: ''
        }
    })

    const [sendDelid, setDelid] = useState({
        id : ''
    })

    useEffect(() => {
        if (sendDelid.id !== '')
        {
            crmservicehere.DeleteRelationdata(JSON.stringify(sendDelid)).then((res) => {
                if (res.status) {
    
                   getRelationDataFun(JSON.stringify(params))
    
                }
            })

        }
    
    }, [sendDelid.id])
    


    const crmservicehere = new CrmService();

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            lead_id: params.id
        }));


        console.log("The params is ", params)

        getBusinessDataFun(JSON.stringify(params))
        getRelationDataFun(JSON.stringify(params))

    }, []);


    const getBusinessDataFun = (z) => {
        crmservicehere.Getbusinessdata(z).then((res) => {
            if (res.status) {

                console.log("Response of get business data is ", res)

                setState(prevState => ({
                    ...prevState,
                    leadsyo: res.data.leads
                }));

                RescleanUpForm()
            }
        })
    }


    const getRelationDataFun = (z) => {
        crmservicehere.GetRelationdata(z).then((res) => {
            if (res.status) {

                console.log("Response of get business data is ", res)

                setState(prevState => ({
                    ...prevState,
                    relationyo: res.data.leads
                }));

            }
        })
    }


    const EditClickAgain = (dumid, dumcarr, dumpoli, dumappsta, dumproline) => {

        window.scrollTo(0, 0);//To go to top

        console.log("The carrier name is ", dumcarr)
        console.log("The policy name is ", dumpoli)
        console.log("The application status is ", dumappsta)
        console.log("The product name is ", dumproline)

        setState(prevState => ({
            ...prevState,
            submitTypehere: 'update',

            update_id: dumid,
            carrier: dumcarr,
            policy: dumpoli,
            application_status: dumappsta,
            product_line: dumproline
        }));


        setColour(prev => ({
            ...prev,
            bg : "#6658b8",
            herecolor : "white",
            buttonbg : "#fff",
            buttoncolor : "#7266ba"
        }))
    }

    const DelShit = (thedelid) => {

        setDelid(prev => ({
            ...prev,
            id : thedelid
        }))      

    }



    //changeHandler 
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setState(prev => ({
            ...prev,
            [name]: value,
            formError: {
                ...prev.formError,
                [name]: ''
            }
        }))
    }

    //changeHandler for relation
    const changeHandlerRela = (e) => {
        const { name, value } = e.target;
        setState(prev => ({
            ...prev,
            [name]: value,
            formErrorRelation: {
                ...prev.formErrorRelation,
                [name]: ''
            }
        }))
    }

    //clean up form after data submitting
    const cleanUpForm = () => {
        setState(prevState => ({
            ...prevState,
            needtoclean: true,

            carrier: '',
            policy: '',
            application_status: '',
            product_line: '',
            update_id: '',
            submitTypehere: 'submit'

        }));

    }

    //clean up form after data submitting
    const cleanUpRegForm = () => {
        setState(prevState => ({
            ...prevState,
            relationship: ''

        }));

    }

    //reset clean up form after data submitting
    const RescleanUpForm = () => {
        setState(prevState => ({
            ...prevState,
            needtoclean: false
        }));

    }





    //submit the form
    const onSubmitForm = (e) => {
        e.preventDefault();
        let isValid = true;
        let FormError = {}

        //validation in carrier name
        if (!state.carrier) {
            isValid = false;
            FormError.carrier = {
                error: 'validation.required',
                field: {
                    field: 'cn'
                }
            }
        }

        //validation in policy number
        // if (!state.policy) {
        //     isValid = false;
        //     FormError.policy = {
        //         error: 'validation.required',
        //         field: {
        //             field: 'pn'
        //         }
        //     }
        // }


        //validation in application status
        if (!state.application_status) {
            isValid = false;
            FormError.application_status = {
                error: 'validation.required',
                field: {
                    field: 'sas'
                }
            }
        }


        //validation in product name
        if (!state.product_line) {
            isValid = false;
            FormError.product_line = {
                error: 'validation.required',
                field: {
                    field: 'pname'
                }
            }
        }


        if (isValid) {
            let FormData = JSON.parse(JSON.stringify(state));
            delete FormData.submitTypehere
            delete FormData.needtoclean
            delete FormData.formError;
            delete FormData.formErrorRelation;
            delete FormData.alert;
            delete FormData.leadsyo
            delete FormData.relationyo


            if (state.submitTypehere === 'submit') {
                delete FormData.update_id

                crmservicehere.Sendbusinessdata(JSON.stringify(FormData)).then(res => {
                    if (res.status) {
                        console.log("The success res is ", res)
                        cleanUpForm()
                        getBusinessDataFun(JSON.stringify(params))

                    }
                    else {
                        console.log("The fail res is ", res)
                        window.scrollTo(0, 0);
                        if (res.error.code === 1073 || res.error.description === "You can't modify the details of this LEAD") {
                            setMsg(prev => ({
                                ...prev,
                                alert: {
                                    show: true,
                                    varient: 'danger',
                                    message: 'This lead details cannot be modified',
                                },
                            }))
                        }

                    }
                })


            }
            else {

                if (state.submitTypehere === 'update') {

                    crmservicehere.Updatebusinessdata(JSON.stringify(FormData)).then(res => {
                        if (res.status) {
                            console.log("The success res is ", res)
                            setColour(prev => ({
                                ...prev,
                                bg : "#fffc",
                                herecolor : "#656565",
                                buttonbg : "#7266ba",
                                buttoncolor : "#fff"
                            }))
                            cleanUpForm()
                            getBusinessDataFun(JSON.stringify(params))

                        }
                        else {
                            console.log("The fail res is ", res)
                        }
                    })

                }



            }


        } else {
            setState(prev => ({
                ...prev,
                formError: {
                    ...prev.formError,
                    ...FormError
                }
            }))
        }

    }



    //submit the form
    const onSubmitRelationForm = (e) => {
        e.preventDefault();
        let isValid = true;
        let FormError = {}

        //validation in carrier name
        if (!state.relationship) {
            isValid = false;
            FormError.relationship = {
                error: 'validation.required',
                field: {
                    field: 'lna'
                }
            }
        }


        if (isValid) {
            let FormData = JSON.parse(JSON.stringify(state));
            delete FormData.submitTypehere
            delete FormData.update_id
            delete FormData.needtoclean
            delete FormData.formError;
            delete FormData.formErrorRelation;
            delete FormData.alert;
            delete FormData.leadsyo
            delete FormData.relationyo


            crmservicehere.AddRelationdata(JSON.stringify(FormData)).then(res => {
                if (res.status) {
                    console.log("The success res is ", res)
                    cleanUpRegForm()
                    getRelationDataFun(JSON.stringify(params))
                }
                else {
                    console.log("The fail res is ", res)
                    console.log("The fail res error is ", res.error.code)
                    if (res.error.code === 1071 || res.error.description === "Invalid Lead") {
                        setState(prev => ({
                            ...prev,
                            formErrorRelation: {
                                ...prev.formErrorRelation,
                                relationship: {
                                    error: 'validation.invalid',
                                    field: {
                                        field: 'lna'
                                    }
                                }
                            }
                        }))

                    }


                    if (res.error.code === 1072 || res.error.description === "Relation Already Exist") {
                        setState(prev => ({
                            ...prev,
                            formErrorRelation: {
                                ...prev.formErrorRelation,
                                relationship: {
                                    error: 'validation.axe',
                                    field: {
                                        field: 'lnaX'
                                    }
                                }
                            }
                        }))

                    }


                }
            })


        } else {
            setState(prev => ({
                ...prev,
                formErrorRelation: {
                    ...prev.formErrorRelation,
                    ...FormError
                }
            }))
        }

    }

    //close alert
    const closeAlert = () => {
        setMsg(prev => ({
            ...prev,
            alert: {
                show: false,
                message: '',
                varient: ''
            }
        }))
    }

    return (
        console.log("We are in business mode"),
        < div className={style.MianContent}>
            <div className={style.search}  style={{"background" : yocolour.bg,"color":yocolour.herecolor}}>
            {/* <div className={style.search}> */}
                <AlertBs
                    {...seemsg.alert}
                    close={closeAlert}
                />
                <Form onSubmit={onSubmitForm}>
                    <FormInputControl
                        label={t('profile.carrier_name')}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'carrier',
                            placeholder: t('Enter Carrier name'),
                            value: (state.needtoclean === true) ? '' : state.carrier,
                            onChange: changeHandler
                        }}
                        error={state.formError.carrier}
                    />

                    <FormInputControl
                        label={t('profile.policy_number')}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'policy',
                            placeholder: t('Enter short description'),
                            value: (state.needtoclean === true) ? '' : state.policy,
                            onChange: changeHandler
                        }}
                        error={state.formError.policy}
                    />

                    <DropDownBs
                        label={t('profile.appliStatus')}
                        required={true}
                        preOption="Select Application status"
                        options={{
                            data: appli_status,
                            value: 'id',
                            label: 'id'
                        }}
                        DropdownProps={{
                            name: "application_status",
                            onChange: changeHandler,
                            value: state.application_status,

                        }}
                        error={state.formError.application_status}
                    />

                    <FormInputControl
                        label={t('profile.productname')}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'product_line',
                            placeholder: t('Type the product name'),
                            value: (state.needtoclean === true) ? '' : state.product_line,
                            onChange: changeHandler
                        }}
                        error={state.formError.product_line}
                    />

                    {/* <FormInputControl
                        label={t('profile.relationship')}
                        inputProps={{
                            type: 'text',
                            name: 'relationship',
                            placeholder: t('Enter Lead ID #'),
                            value: state.relationship,
                            onChange: changeHandler
                        }}
                        error={state.formError.relationship}
                    /> */}

                    {/* <FormInputControl
                        label={t('profile.description')}
                        inputProps={{
                            type: 'text',
                            name: 'description',
                            placeholder: t('Enter description'),
                            value: state.description,
                            onChange: changeHandler
                        }}
                        error={state.formError.description}
                    /> */}

                    <CustomButton
                        type="submit"
                        style = {{"background" : yocolour.buttonbg,"color" : yocolour.buttoncolor}}
                        variant="primary"
                    >
                        {
                            state.submitTypehere === 'submit' ? t('Button.submit') : t('Button.update')
                        }

                        {/* {
                            t('Button.submit') 
                        } */}


                    </CustomButton>



                </Form >


            </div>

            <div style={{ "marginTop": "20px" }} className={style.search}>
                <Form onSubmit={onSubmitRelationForm}>

                    <FormInputControl
                        label={t('profile.relationship')}
                        inputProps={{
                            type: 'text',
                            name: 'relationship',
                            placeholder: t('Enter Lead ID #'),
                            value: state.relationship,
                            onChange: changeHandlerRela
                        }}
                        error={state.formErrorRelation.relationship}
                    />


                    <CustomButton
                        type="submit"
                        variant="primary"
                    >
                        {
                            t('Button.submit')
                        }


                    </CustomButton>



                </Form >


            </div>


            <Row className="mt-4">
                <Col>
                    <Card>
                        <Card.Body>
                            <DataTable
                                columns={columns}
                                data={state.leadsyo}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


            <Row className="mt-4">
                <Col>
                    <Card>
                        <Card.Body>
                            <DataTable
                                columns={relationColumns}
                                data={state.relationyo}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


        </ div >
    )
}

export default EditnAddBusinessHere



