import React, { useCallback, useEffect, useState } from 'react';
import style from './crm.module.scss';
import { Form } from 'react-bootstrap';
import { Multiselect } from "multiselect-react-dropdown";
import { DatePickers, FormInputControl, DropDownBs, CustomButton, AlertBs } from '../common';
import moment from 'moment';
//service
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CrmService from '../../../service/crm/Crm';
import AppinfoService from '../../../service/common/Appinfo';
const leadStatus = [
    {
        id: 'Ongoing'
    },
    {
        id: 'Rejected'
    },
    {
        id: 'Accepted'
    }

];
const interest = [
    {
        id: 'Very Interested'
    },
    {
        id: 'Interested'
    },
    {
        id: 'Not That Interested'
    }
];

const testshit = [
    {
        key: 'First key',
        value: 'First value',
    },
    {
        key: 'Second key',
        value: 'Second value',
    }
];
const gender = [
    {
        id: 'Male'
    },
    {
        id: 'Female'
    }
];
const InitialForm = {
    first_name: '',
    last_name: '',
    email_id: '',
    skype_id: '',
    mobile_no: '',
    // country: '',
    city: '',
    states: '',
    statesList: '',
    leadOriginList : '',
    selectedLeadOrigin : '',
    // leadProductList : '',
    selectedLeadProduct : '',
    leadProductList : [],
    // selectedLeadProduct : [],

    // replicaTypeList: '', //previously when we needed to select only one element
    // replicaTypeList: [],
    // replica_types: '', //previously when we needed to select only one element
    // replica_types: [],
    street_address: '',
    zip_code: '',
    description: '',
    followup_date: '',
    lead_status: 'Ongoing',
    status_change_date: '',
    interest_status: 'Interested',
    gender_status: 'Male',

    formError: {
        first_name: '',
        last_name: '',
        email_id: '',
        skype_id: '',
        mobile_no: '',
        // country: '',
        city: '',
        states: '',
        // replica_types: '',
        selectedLeadOrigin: '',
        selectedLeadProduct : '',
        street_address: '',
        zip_code: '',
        description: '',
        followup_date: '',
        lead_status: '',
        status_change_date: '',
        interest_status: '',
        gender_status: '',
        id: ''
    }
}
function AddForm(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const service = new CrmService();
    const [status, setStatus] = useState(leadStatus);
    const [state, setState] = useState({
        ...InitialForm,
        alert: {
            show: false,
            message: '',
            varient: ''
        },
        editLoader: false,
    })

    const [repoptions, setRepOptions] = useState({
        selectedRepValues: [],
    });

    const {
        selectedRepValues
    } = repoptions;



    // multi select css components
    let styles = {
        multiselectContainer: { // To change css for multiselect (Width,height,etc..)
            marginTop: "15px",
            minWidth: "100%",
            maxWidth: "max-content",
            display: "inline-block",
            marginBottom: "20px"
        },
        inputField: { // To change input field position or margin
            margin: "5px",
            // width:"200px",
            minWidth: "300px",
            maxWidth: "max-content",
        },
        optionContainer: { // To change css for option container 
            boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
            border: "none"
        },
        searchBox: { // To change search box element look
            padding: "2px",
            border : "1px solid #dee2e6",
            marginRight: "5px",
            marginTop: "-10px",
            paddingLeft: "5px"
        },
        option: { // To change css for dropdown options
            color: "black"
        },
    }



    //on select the multi select
    const selectRephandler = (e) => {

        setRepOptions(prev => ({
            ...prev,
            selectedRepValues: e,
        })
        );

        setState(prev => ({
            ...prev,
            selectedLeadProduct:e
            // selectedLeadProduct: JSON.stringify(e)
            // selectedLeadProduct: JSON.stringify(e.toString())
        }))

    }

    //remove selected items in multi select

    const removeRepHandler = (e) => {
        setRepOptions(prev => ({
            ...prev,
            selectedRepValues: e,
        }));

        setState(prev=>({
            ...prev,
            selectedLeadProduct : e
        }))
    }


    //intial loading the country 
    //get the country in inital loading 
    const getCountry = useCallback(() => {
        const commonService = new AppinfoService();

        // commonService.getCountry().then(res => {
        //     if (res.status) {
        //         setState(prev => ({
        //             ...prev,
        //             countryList: res.data.country
        //         }))
        //     } else {
        //         if (res.error.code === 1002) {
        //             history.push('/logout')
        //         }
        //     }
        // })

        commonService.getStuff().then(res => {
            if (res.status) {
                setState(prev => ({
                    ...prev,
                    statesList: res.data.states,
                    leadOriginList: res.data.types, 
                    leadProductList: res.data.replica_types //new because of multiselect takes only key and value
                    // replicaTypeList: res.data.types //previous whn there were code, title and value and only when one value only needed to be selected
                }))
                console.log("The stateslist is ", state.statesList)
                console.log("The Lead origin list is is ", state.leadOriginList)
                console.log("The Lead product list is is ", state.leadProductList)
            } else {
                if (res.error.code === 1002) {
                    history.push('/logout')
                }
            }

            console.log("The stateslist is ", state.statesList)
            // console.log("The replicatypeList is ", state.replicaTypeList)
            console.log("The Lead origin list is is ", state.leadOriginList)
            console.log("The Lead product list is is ", state.leadProductList)


        })


    }, [history])


    useEffect(() => {
        getCountry();
    }, [getCountry]);


    //get the edit data
    useEffect(() => {
        const { data, id } = props;

        setState(prev => ({
            ...prev,
            id: id,
            ...data
        }));
        // if(id){
        //     const addStatus = status;
        //     addStatus.push({
        //         id : 'Accepted'
        //     })
        //     setStatus(addStatus)
        // }
    }, [props, status])

    //changeHandler 
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setState(prev => ({
            ...prev,
            [name]: value,
            formError: {
                ...prev.formError,
                [name]: ''
            }
        }))
    }
    //date change function
    const datechange = (date, id) => {
        setState(prev => ({
            ...prev,
            [id]: date,
            formError: {
                ...prev.formError,
                [id]: ''
            }
        }))
    }

    //submit the 
    const onSubmitForm = (e) => {
        e.preventDefault();
        let isValid = true;
        let FormError = {}
        //validation in first nanme 
        if (!state.first_name) {
            isValid = false;
            FormError.first_name = {
                error: 'validation.required',
                field: {
                    field: 'firstName'
                }
            }
        }

        //validation in second name
        if (!state.last_name) {
            isValid = false;
            FormError.last_name = {
                error: 'validation.required',
                field: {
                    field: 'lastName'
                }
            }
        }


        //validation in zip code
        if (!state.zip_code) {
            isValid = false;
            FormError.zip_code = {
                error: 'validation.required',
                field: {
                    field: 'zipCode'
                }
            }
        }

        //validation in lead origin
        if (!state.selectedLeadOrigin) {
            isValid = false;
            FormError.selectedLeadOrigin = {
                error: 'validation.required',
                field: {
                    field: 'leadorivenam'
                }
            }
        }


        //validation in discription
        if (!state.description) {
            isValid = false;
            FormError.description = {
                error: 'validation.required',
                field: {
                    field: 'description'
                }
            }
        }


        //validation in follow up date
        if (!state.followup_date) {
            isValid = false;
            FormError.followup_date = {
                error: 'validation.required',
                field: {
                    field: 'nextFollowupDate'
                }
            }
        }

        //validation in email
        if (!state.email_id) {
            isValid = false;
            FormError.email_id = {
                error: 'validation.required',
                field: {
                    field: 'email'
                }
            }
        }

        //validation in mobile number
        if (!state.mobile_no) {
            isValid = false;
            FormError.mobile_no = {
                error: 'validation.required',
                field: {
                    field: 'mobile'
                }
            }
        }




        if (state.mobile_no) {
            var regexPatternMobile = new RegExp(/^[0-9]{10,15}$/);
            if (!regexPatternMobile.test(state.mobile_no)) {
                isValid = false;
                FormError.mobile_no = {
                    error: 'validation.fieldAreNotCurrectFormat',
                    field: {
                        field: 'mobile'
                    }
                }
            }

        }


        if (state.email_id) {
            var regexPattern = new RegExp(/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/);
            if (!regexPattern.test(state.email_id)) {
                isValid = false;
                FormError.email_id = {
                    error: 'validation.fieldAreNotCurrectFormat',
                    field: {
                        field: 'email_id'
                    }
                }
            }

        }





        if (isValid) {
            const today = moment();
            let FormData = JSON.parse(JSON.stringify(state));
            delete FormData.formError;
            delete FormData.alert;
            // delete FormData.countryList;
            delete FormData.statesList;
            delete FormData.leadOriginList;
            delete FormData.leadProductList;
            // delete FormData.replicaTypeList;


            FormData.followup_date = moment(FormData.followup_date).format('yyyy-MM-DD');
            FormData.status_change_date = today.format('yyyy-MM-DD');
            if (props.isUpdate) {
                setState(prev => ({
                    ...prev,
                    editLoader: true,
                }))
                service.EditLead(FormData, props.id).then(res => {
                    if (res.status) {
                        setState(prev => ({
                            ...prev,
                            ...InitialForm,
                            editLoader: false,
                        }))
                        if (props.submitCallback) {
                            props.submitCallback();
                        } else {
                            setState(prev => ({
                                ...prev,
                                alert: {
                                    show: true,
                                    varient: 'success',
                                    message: 'validation.leadUpdatedSuccessfully'
                                }
                            }))
                        }
                        // props.LeadData();
                    } else {
                        if (res.error.code === 1002) {
                            history.push('/logout')
                        } else if (res.error.code === 1057) {
                            history.push({
                                pathname: '/dashboard',
                                state: {
                                    error: true,
                                    message: 'permissionDinied'
                                }
                            })
                        } else if (res.error.code === 1063 || res.error.code === 1004) {
                            setState(prev => ({
                                ...prev,
                                alert: {
                                    show: true,
                                    varient: 'danger',
                                    message: 'validation.checkValueYouHaveSubmitted'
                                },
                                editLoader: false,
                            }))
                        }
                    }
                })
            } else {
                setState(prev => ({
                    ...prev,
                    editLoader: true,
                }))
                service.AddLead(FormData).then((res) => {
                    if (res.status) {
                        setState(prev => ({
                            ...prev,
                            alert: {
                                show: true,
                                varient: 'success',
                                message: 'validation.leadAddedSuccessfully'
                            },
                            editLoader: false,
                            ...InitialForm
                        }))
                    } else {
                        if (res.error.code === 1002) {
                            history.push('/logout')
                        } else if (res.error.code === 1004) {
                            const { formError } = state;
                            let validationError = {};
                            for (const property in res.error.fields) {
                                if (formError.hasOwnProperty(property)) {
                                    validationError.followup_date = {
                                        error: `validation.${res.error.fields[`${property}_err`]}`,
                                        field: {
                                            field: property
                                        }
                                    }
                                }
                            }
                            setState(prev => ({
                                ...prev,
                                editLoader: false,
                                formError: {
                                    ...prev.formError,
                                    validationError
                                }
                            }))
                        }
                        else if (res.error.code === 1062) {
                            setState(prev => ({
                                ...prev,
                                editLoader: false,
                                alert: {
                                    show: true,
                                    varient: 'danger',
                                    message: 'validation.emailAlreadyExists'
                                }
                            }))

                        }
                    }
                });
            }
        } else {
            setState(prev => ({
                ...prev,
                formError: {
                    ...prev.formError,
                    ...FormError
                },
                editLoader: false,
                alert: {
                    show: true,
                    varient: 'danger',
                    message: 'validation.checkValueYouHaveSubmitted'
                }
            }))
        }
    }
    //close alert
    const closeAlert = () => {
        setState(prev => ({
            ...prev,
            alert: {
                show: false,
                message: '',
                varient: ''
            }
        }))
    }
    return (
        console.log("the selected values of multiselect is ", selectedRepValues),
        // console.log("the values of lead origin list is ", state.leadOriginList),
        // console.log("the selected lead origin is ", state.selectedLeadOrigin),        
        // console.log("the values of lead product list is ", state.leadProductList),
        console.log("the selected lead prosuct is ", state.selectedLeadProduct),
        // console.log("the testshit is ", testshit),
        <div className={`${style.panel} ${style.papanel_defaultnel}`}>
            <div className={`${style.panel_body}`}>
                <AlertBs
                    {...state.alert}
                    close={closeAlert}
                />
                <Form onSubmit={onSubmitForm} >
                    <FormInputControl
                        label={t('profile.firstName')}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'first_name',
                            placeholder: t('First Name'),
                            value: state.first_name,
                            onChange: changeHandler
                        }}
                        error={state.formError.first_name}
                    />
                    <FormInputControl
                        label={t("profile.lastName")}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'last_name',
                            placeholder: t('Last Name'),
                            value: state.last_name,
                            onChange: changeHandler
                        }}
                        error={state.formError.last_name}
                    />
                    <DropDownBs
                        label={t('Crm.gender')}
                        options={{
                            data: gender,
                            value: 'id',
                            label: 'id'
                        }}
                        DropdownProps={{
                            name: "gender_status",
                            onChange: changeHandler,
                            value: state.gender_status,

                        }}
                    />
                    <FormInputControl
                        label={t('profile.email')}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'email_id',
                            placeholder: t('Email ID'),
                            value: state.email_id,
                            onChange: changeHandler
                        }}
                        error={state.formError.email_id}
                    />

                    <FormInputControl
                        label={t('Common.skype')}
                        inputProps={{
                            type: 'text',
                            name: 'skype_id',
                            placeholder: t('Skype ID'),
                            value: state.skype_id,
                            onChange: changeHandler
                        }}
                    />
                    <FormInputControl
                        label={t('profile.mobile')}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'mobile_no',
                            placeholder: t('Mobile No'),
                            value: state.mobile_no,
                            onChange: changeHandler
                        }}

                        error={state.formError.mobile_no}
                    />
                    {/* <DropDownBs
                        label={t('profile.country')}
                        preOption="country"
                        options={{
                            data: state.countryList,
                            value: 'value',
                            label: 'title'
                        }}
                        DropdownProps={{
                            name: "country",
                            onChange: changeHandler,
                            value: state.country
                        }}
                    /> */}

                    <DropDownBs
                        label={t('profile.states')}
                        preOption="States"
                        options={{
                            data: state.statesList,
                            value: 'value',
                            label: 'title'
                        }}
                        DropdownProps={{
                            name: "states",
                            onChange: changeHandler,
                            value: state.states
                        }}
                    />

                    <DropDownBs
                        label={"Lead Origin"}
                        required={true}
                        preOption="Select Lead origin"
                        options={{
                            data: state.leadOriginList,
                            value: 'value',
                            label: 'title'
                        }}
                        DropdownProps={{
                            name: "selectedLeadOrigin",
                            onChange: changeHandler,
                            value: state.selectedLeadOrigin
                        }}
                        error={state.formError.selectedLeadOrigin}
                    />     

                    <label style={{"marginBottom":"-30px", "fontSize": "15px"}}>Lead Capture Products</label><br />
                    <Multiselect
                        singleSelect={false}
                        options={state.leadProductList}
                        // options={testshit}
                        className="col-md-3 col-sm-12"
                        showCheckbox={true}
                        style={styles}
                        onSelect={selectRephandler}
                        onRemove={removeRepHandler}
                        displayValue="key"
                        avoidHighlightFirstOption={true}
                        placeholder={'Select your options'}
                        selectedValues={props.seetheselectedleadproduct === ""? selectedRepValues :props.seetheselectedleadproduct }
                        // hidePlaceholder={selectedRepValues.length === 0 ? false : true}
                        hidePlaceholder={props.seetheselectedleadproduct === ""?   selectedRepValues.length === 0 ? false : true  : true} 
                    />






                    <FormInputControl
                        label={t('Common.city')}
                        inputProps={{
                            type: 'text',
                            name: 'city',
                            placeholder: t('City'),
                            value: state.city,
                            onChange: changeHandler
                        }}
                    />

                    <FormInputControl
                        label={"Street Address"}
                        inputProps={{
                            type: 'text',
                            name: 'street_address',
                            placeholder: t('Street Address'),
                            value: state.street_address,
                            onChange: changeHandler
                        }}
                    />

                    <FormInputControl
                        label={t('Common.zipCode')}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'zip_code',
                            placeholder: t('Zipcode'),
                            value: state.zip_code,
                            onChange: changeHandler
                        }}
                        error={state.formError.zip_code}
                    />



                    <FormInputControl
                        label={t('profile.description')}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'description',
                            placeholder: t('Description'),
                            value: state.description,
                            onChange: changeHandler
                        }}
                        error={state.formError.description}
                    />


                    <DropDownBs
                        label={t('Crm.levelOfInterest')}
                        options={{
                            data: interest,
                            value: 'id',
                            label: 'id'
                        }}
                        DropdownProps={{
                            name: "interest_status",
                            onChange: changeHandler,
                            value: state.interest_status,

                        }}
                    />
                    {
                        !props.isUpdate &&
                        <DatePickers
                            label={t('Crm.nextFollowupDate')}
                            start={state.followup_date}
                            placeholderText={t('Common.fromDate')}
                            datechange={datechange}
                            id="followup_date"
                            error={state.formError.followup_date}
                            required={true}
                            minDate={moment().toDate()}
                        />
                    }
                    <DropDownBs
                        label={t('Crm.leadStatus')}
                        options={{
                            data: status,
                            value: 'id',
                            label: 'id'
                        }}
                        DropdownProps={{
                            name: "lead_status",
                            onChange: changeHandler,
                            value: state.lead_status
                        }}
                    />
                    <CustomButton
                        type="submit"
                        variant="primary"
                    >
                        {state.editLoader ?
                            <i className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            /> : ''}

                        {t(`Button.${props.BtnName}`)}
                    </CustomButton>
                </Form>
            </div>
        </div>
    );
}
export default AddForm

