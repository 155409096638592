import React, { setState } from "react";
import { useTranslation } from "react-i18next";
import { FormInputControl, DropDownBs, DatePickers } from "../common";
import { Form } from "react-bootstrap";
import style from "../profile/profile.module.scss";

function FormsControl(props) {
  console.log(props)

  const { t } = useTranslation();

  //return components
  const Components = (type) => {
    if (type === "text") {
      return (
        <FormInputControl
          label={props.data.title}
          required={props.data.required}
          inputProps={{
            id: props.data.code,
            name: props.data.field_name,
            type: "text",
            value: props.data.value ?? "",
            disabled: !props.data.isEditable,
            onChange: (e) => props.change(e),
          }}
        />
      );
    } 
  };
  return Components('text');
}

export default FormsControl;
