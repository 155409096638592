import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import { ReplicaContent } from '../../components/tools/';
import ReplicaService from '../../../service/tools/replica';
import { withRouter } from 'react-router';
import companyName from '../../../store/mobxStore/companyName';
import Spinner from '../../shared/Spinner';
// import { ToastContainer,toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import {AlertMessage} from '../../components/common';
import { AlertMessage } from '../../components/common';



function ReplicaConfiguration(props) {
    let service = new ReplicaService()

    const [state, setState] = useState({
        changedImage: {
            uploadFile: null,
            file: null,
            name: '',
            show: false,
            videoUrl: null
        },
        replica_banner: '',
        hereOptionsStuff: {},
        selectBoxName: '',
        mainfilterType: [],
        loader: false,
        uploader: false,
        selectedValues: [],
        bannerType: [],
        selectedBannerType: "",
        validURl:true,
        alert: {
            show: false,
            message: '',
            varient: ''
        },
        FormError:{
            url:''
        }
        // notify : {
        //     show  :false,
        //     message : '',
        //     header : '',
        //     type : ''
        // }

    })

    //  //notified closed handler
    //  const notifyDissmissed = () =>{
    //     setState(prev => ({
    //         ...prev,
    //         IsError : false,
    //         notifyMessage : ''
    //       }));
    //       if(notyfiType ==='success'){
    //           handleClose()
    //       }
    //  }

    useEffect(() => {
        getReplicaBanner();
        // console.log("Within useeffect hereOptionsStuff ", state.hereOptionsStuff)
    }, []);

    const options = [
        { key: 'Image', value: 'image' },
        { key: 'Video', value: 'video' },

    ];

    const{FormError}=state

    const videoUrlchangehandler = (e) => {
        FormError.url=''
        
        setState((prev) => ({
            ...prev,
            changedImage: {
                ...prev.changedImage,
                videoUrl: e.target.value
            }
        }))

        validation(e.target.value)
        
        
    }

    const validation=()=>{
        
        const regExp=/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
        if(!regExp.test(state.changedImage.videoUrl)){
            
            setState((prev)=>({
                ...prev,
                validURl:false
            }))
        }else{
            setState((prev)=>({
                ...prev,
                validURl:true
            }))
        }
    }

    const videoBlurhandler = (e) => {
        console.log('valueee', e.target.value)
        if (/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(e.target.value)) {
            setState((prev) => ({
                ...prev,
                validURl: true
            }))
        }
        else {
            // setState((prev) => ({
            //     ...prev,
            //     validURl:false,
            //     alert: {
            //         ...prev.alert,
            //         show: true,
            //         varient: "danger",
            //         message: t("validation.invalidUrl")
            //     }
            // }))
        }
    }



    console.log('selectedBannerType', state.changedImage.videoUrl)
    const getReplicaBanner = () => {
        setState(prevstate => ({
            ...prevstate,
            loader: true,
        }))
        service.getReplicaBanner(state.keyword)
            .then(res => {
                if (res.status) {

                    const filterType = res.data.category.options.map((newdata) => {
                        return {
                            key: newdata.code,
                            value: newdata.value
                        }
                    })

                    setState(prevstate => ({
                        ...prevstate,
                        replica_banner: res.data.replica_banner,
                        hereOptionsStuff: res.data.category.options,
                        selectBoxName: res.data.category.field_name,
                        mainfilterType: filterType,
                        loader: false
                    }))
                    // console.log("The replica_banner is ", state.replica_banner)
                    // console.log("The hereOptionsStuff is ", state.hereOptionsStuff)
                } else {
                    setState(prevstate => ({
                        ...prevstate,
                        loader: false,
                    }))
                    if (res.error.code === 1002) {
                        props.history.push('/logout');
                    } else if (res.error.code === 1057) {
                        props.history.push({
                            pathname: '/dashboard',
                            state: {
                                error: true,
                                message: 'permissionDenied'
                            }
                        })
                    }
                }
                // console.log("The response of Replica config is ", res)

            });



    }

    const changeBanner = e => {
        setState(prevstate => ({
            ...prevstate,
            changedImage: {
                uploadFile: null,
                file: null,
                name: '',
                show: false
            }
        }))
        let reader = new FileReader();
        reader.onload = event => {
            setState(prevstate => ({
                ...prevstate,
                changedImage: {
                    uploadFile: e.target.files[0],
                    file: event.target.result,
                    name: e.target.files[0].name,
                    show: true
                }
            }))
        }
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
    }
    const resetBanner = () => {
        setState(prevstate => ({
            ...prevstate,
            changedImage: {
                uploadFile: null,
                file: null,
                name: '',
                show: false
            }
        }))
    }





    const uploadBanner = (selectedvalues) => {
        console.log('slectedvalues', selectedvalues)
        console.log('enterdeheer=====================')
        // const   uploadBanner = (e) => {
        // console.log("Selected values are reaching in parent component as ",selectedvalues)
        if (selectedvalues.length === 0 || selectedvalues === null) {
            let error_message = 'validation.selectreplicaTYpe';

            setState(prevstate => ({
                ...prevstate,
                alert: {
                    show: true,
                    message: t(error_message),
                    varient: 'danger'
                },
                uploader: false,
            }));


        }
        else {

            let formData = new FormData();
            if (state.selectedBannerType === "Image") {
                formData.append('image', state.changedImage.uploadFile)
            } else if (state.selectedBannerType === "Video") {
                if (/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(state.changedImage.videoUrl)) {
                    formData.append('video', state.changedImage.videoUrl)
                } else {
                    setState((prev) => ({
                        ...prev,
                        alert: {
                            ...prev.alert,
                            show: true,
                            varient: "danger",
                            message: t("validation.invalidUrl")
                        }
                    }))
                }

            }
            formData.append('type', state.selectBoxName)
            formData.append('key', selectedvalues[0].key)
            formData.append('bannerType', state.selectedBannerType)
            setState(prevstate => ({
                ...prevstate,
                uploader: true,
            }))
            service.updateBanner(formData).then(res => {
                if (res.status) {
                    // this.getLeads();
                    setState(prevstate => ({
                        ...prevstate,
                        alert: {
                            show: true,
                            message: t('Common.top_banner_updated'),
                            varient: 'success'
                        },
                        changedImage: {
                            uploadFile: null,
                            file: null,
                            name: '',
                            show: false
                        },
                        uploader: false,
                    }))
                    getReplicaBanner();
                } else {
                    let error_message = '';
                    if (res.error.code === 1038) {
                        error_message = 'validation.tooManyUploadLimit';
                    } else if (res.error.code === 1017) {
                        error_message = 'validation.fileTypeNotSupported';
                    } else if (res.error.code === 1018) {
                        error_message = 'validation.fileTypeExceeded';
                    } else if (res.error.code === 1024) {
                        error_message = 'Common.error_file_upload';
                    }
                    else if (res.error.code === 1070) {
                        error_message = 'validation.sofdd';
                    }
                    else if (res.error.code === 1002) {
                        props.history.push('/logout');
                    } else if (res.error.code === 1032) {
                        error_message = 'validaion.chooseFile';
                    }
                    setState(prevstate => ({
                        ...prevstate,
                        alert: {
                            show: true,
                            message: error_message,
                            varient: 'danger'
                        },
                        uploader: false,
                    }));
                }
            })


        }



    }

    const selectVideoTYpe = (e) => {
        console.log('typeeee', e[0].key)
        setState((prev) => ({
            ...prev,
            bannerType: e,
            selectedBannerType: e[0].key
        }))
    }
    console.log('bannertypee', state.bannerType)

    const { t } = props

    const closeAlert = () => {
        setState((prev) => ({
            ...prev,
            alert: {
                ...prev.alert,
                show: false,
                varient: "",
                message: ""
            }
        }))
    }
    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.replica_configuration')}</title>
            </Helmet>
            <PageTitle
                title="replica_configuration"
                buttonOn={false}
            />
            {state.loader ?
                <Spinner />

                :
                <>
                    {
                        state.alert.show && (
                            <AlertMessage
                                show={state.alert.show}
                                message={state.alert.message}
                                type={state.alert.varient}
                                dismiss={closeAlert}
                            />
                        )

                    }
                    <ReplicaContent
                        {...state}
                        alert={state.alert}
                        changedImage={state.changedImage}
                        changeBanner={changeBanner}
                        resetBanner={resetBanner}
                        uploadBanner={uploadBanner}
                        replica_banner={state.replica_banner}
                        hereOptionsStuff={state.hereOptionsStuff}
                        selectBoxName={state.selectBoxName}
                        mainfilterType={state.mainfilterType}
                        BannerType={options}
                        selectVideoTYpe={selectVideoTYpe}
                        videoUrlchangehandler={videoUrlchangehandler}
                        videoBlurhandler={videoBlurhandler}

                    ></ReplicaContent>
                </>

            }

        </div>
    );

}
export default withTranslation()(withRouter(ReplicaConfiguration))