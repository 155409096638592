import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import style from './tools.module.scss';
import { Multiselect } from "multiselect-react-dropdown";
import AddonNotification from '../../components/common/AddonNotification';
import { useTranslation } from 'react-i18next';
// import {useTranslation} from 'react-i18next';
// import moment from 'moment';
import AlertBs from '../common/AlertBs';

const ReplicaContent = (props) => {

    console.log('replicaa', props)
    // console.log("The selected state values is ", state.selectedValues)
    const { t } = useTranslation();

    const [state, setState] = useState({
        hereOptionsStuff: props.hereOptionsStuff,
        selectBoxName: props.selectBoxName,
        selectedValues: [],
        replicaSelected: false
    })

    // const {
    //     selectedValues
    // } = state;


    // useEffect(()=>{

    //     // if(state.selectedValues = ''){
    //     //     if(type === 'danger'){
    //     //         toast.error(message)
    //     //     }else if(type === 'success'){
    //     //         toast.success(message)
    //     //     }  
    //     //     else  
    //     //     toast.error(message)        
    //     // }

    // },[])

    // select items
    const selecthandler = (e) => {
        setState(prev => ({
            ...prev,
            selectedValues: e,
            replicaSelected: true
        }));

        console.log("The e value is ", e)
        console.log("The selectedValues value in state is ", state.selectedValues)
    }


    // multi select css components
    let styles = {
        multiselectContainer: { // To change css for multiselect (Width,height,etc..)
            marginTop: "15px",
            minWidth: "250px",
            maxWidth: "max-content",
            display: "inline-block",
            marginBottom: "30px"
        },
        inputField: { // To change input field position or margin
            margin: "5px",
            width: "200px",
        },
        // chips: { // To change css chips(Selected options)
        //   background : "rgb(228, 228, 228)",
        //   color : "rgb(88, 102, 110)",
        //   borderRadius : "10px",
        //   height : "20px"
        // },
        optionContainer: { // To change css for option container 
            boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
            border: "none"
        },
        searchBox: { // To change search box element look
            padding: 0,
            marginRight: "5px",
            paddingLeft: "5px"
        },
        option: { // To change css for dropdown options
            color: "black"
        },
    }

    let uploadtitle = {
        display: "block"
    }

    let imagePreview = '';
    if (props.changedImage.show) {
        imagePreview = (

            <div className={style.previewZone}>
                <div className={style.box}>
                    <div className={style.boxHeader}>
                        <div>{t('Button.preview')}</div>
                        <div className={style.boxTools}>
                            <button type="button" onClick={props.resetBanner} className={style.removePreview}>
                                <i className={style.faTimes}></i>
                                {t('Button.reset')}
                            </button>
                        </div>
                    </div>
                    <div className={style.boxBody}>
                        <img width="200" src={props.changedImage.file} alt="" />
                        <p>{props.changedImage.name}</p>
                    </div>
                </div>
            </div>
        );
    }

    let uploadBtn = '';
    if (props.changedImage.uploadFile || props.changedImage.videoUrl) {
        uploadBtn = (
            // <Button variant="primary" type="button" onClick={props.uploadBanner} className={`"mtp30 ml-1" ${style.uploadButton}`}>
            // <Button variant="primary" type="button" onClick={() => props.uploadBanner(state.selectedValues)} className={`"mtp30 ml-1" ${style.uploadButton}`}>
            <Button variant="primary" type="button" onClick={() => props.uploadBanner(state.selectedValues)} className={`"mtp30 ml-1" ${style.uploadButton}`} disabled={props.selectedBannerType==="Video" &&!props.validURl}>
                {console.log("The selected values inside upload button is ", state.selectedValues)}

                {props.uploader ?
                    <i className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                    /> : ''}

                Upload
            </Button>
        );
    }

    return (
        <div className={style.downloadConatiner}>
            {/* {seeType()} */}
            {console.log("The selected state values is ", state.selectedValues)}
            {console.log("The length of selected state values is ", (state.selectedValues.length))}


            <Row>
                <Col>
                    {/* <AlertBs
                        {...props.alert}
                        close={props.closeAlert}
                    /> */}
                    <Card>
                        <Card.Body className={style.replicaContent}>
                            <div>

                                <h4>{t('Common.currenttopbanner')}</h4>
                                <div className={style.uploadContainer}>
                                    <img src={props.replica_banner} alt="" />
                                </div>

                                <label>{t('Common.BannerType')}</label><br />
                                <Multiselect
                                    options={props.BannerType}
                                    singleSelect={true}
                                    style={styles}
                                    onSelect={props.selectVideoTYpe}
                                    displayValue="key"
                                    avoidHighlightFirstOption={true}
                                    // placeholder={t('Common.type')}
                                    selectedValues={props.bannerType}

                                /><br />

                                <label>{t('Common.replicaType')}</label><br />
                                <Multiselect
                                    options={props.mainfilterType}
                                    singleSelect={true}
                                    style={styles}
                                    onSelect={selecthandler}
                                    displayValue="key"
                                    avoidHighlightFirstOption={true}
                                    // placeholder={t('Common.type')}
                                    selectedValues={state.selectedValues}

                                />

                                {imagePreview}


                                {
                                    props.selectedBannerType === "Image" && (
                                        <>
                                            <Form.Label style={uploadtitle}>{t('Common.uploadtopbanner')}</Form.Label>

                                            <div className={style.dropzoneWrapper}>
                                                <div className={style.dropzoneDesc}>
                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                    <p>{t('Common.chooseimagefiledrag')}</p>
                                                    <div className={style.dropzoneDesc2}>

                                                        <span className="">{t('Common.chooseimgfile')}</span><br />
                                                        <span className="">{t('Common.maxsize')}</span>
                                                    </div>
                                                </div>
                                                <input type="file" name="banner_image" id="banner_image" className={style.dropzone} onChange={props.changeBanner} />
                                            </div></>

                                    )
                                }
                                {
                                    props.selectedBannerType === "Video" && (
                                        <>
                                            <div className={style.dropzoneWrappervideo}>
                                                <Form.Label style={uploadtitle}>{t('Common.uploadVideo')}</Form.Label>
                                                {/* <input type="url" id="url-input" name="url" required  value={props.changedImage.videoUrl} onChange={props.videoUrlchangehandler}></input> */}
                                                <Form.Control 
                                                className={(!props.validURl)?style.error:''}
                                                type="url" 
                                                value={props.changedImage.videoUrl} 
                                                onChange={props.videoUrlchangehandler} 
                                                onBlur={props.videoBlurhandler}
                                                />
                                                {!props.validURl?<span style={{fontSize:'13px',color:'red'}}>{t('validation.invalidUrl')}</span>:""}

                                            </div>

                                        </>

                                    )
                                }





                            </div>
                            {uploadBtn}
                        </Card.Body>
                        {/* <Card.Body className={style.replicaContent}>
                        <Button >{t('Button.update')}</Button>
                        </Card.Body> */}
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ReplicaContent;