import React from 'react';
//css
import style from './crm.module.scss';
//service-
import {useTranslation} from 'react-i18next';
import { ProgressBar,Button} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
function FollowUpRecentTable(props) {
    const {t}  = useTranslation();
    const history = useHistory();
    const ViewLeadClick  = id =>{
        history.push(`/crm/timeline/${id}`)
    }
    const follow_data = props.followupdata&&props.followupdata.map((item, key) => {
        
        return (
            // console.log("The item in follow data is this baby ",item),
            <tr key={key}>             
                <td>{key+1}</td>
                <td>{item.first_name}</td>
                <td>{item.skype_id}</td>
                <td>{item.lead_origin}</td>
                <td> <ProgressBar active="true" striped  animated  variant={item.lead_completeness>0 && item.lead_completeness<=40?'info':item.lead_completeness>=41&&item.lead_completeness<=80?'warning':item.lead_completeness>=81&&item.lead_completeness<=90?'danger':'success'} label={`${item.lead_completeness}%`} now={item.lead_completeness}  className={item.lead_completeness>50 ? `progress-bar` : null} className={`${style.progress}`} key={1} /></td>
                <td>{item.date}</td>
                <td><i className={`${style.text_info} fa fa-edit`} onClick={()=>{props.showModal('edit',item.id,'followuprecent')}} ></i></td>
                <td><i className={`${style.text_primary} fa fa-plus-circle`} onClick={()=>{props.showModal('addFollowup',item.id,'followuprecent')}}></i></td>
                <td><i className={`${style.text_primary} fa fa-arrow-circle-up `} onClick={()=>{props.showModal('NextFollowup',item.id,'followuprecent')}}></i></td>
                <td><i className={`${style.text_primary} fa fa-book`} onClick={()=>ViewLeadClick(item.id)} ></i></td>
                <td><Button style={{"fontSize":"0.75rem"}} onClick={()=>props.seeBusiness(item.lead_id)}>{t('Sidemenu.addeditbusiness')}</Button></td>
            </tr>
        )
    });
    return (
                <div className={`${style.panel} ${style.papanel_defaultnel}`}>
                    <div className={`${style.panel_body}`}>
                    <legend><span className={`${style.fieldset_legend}`}>{props.title}</span></legend>
                        <div className={`${style.table_responsive} ${style.panel} ${style.papanel_defaultnel}`}>
                            { props.followupdata && props.followupdata.length >0 ? 
                            
                            <table className={`table`} >
                            <thead>
                                <tr> 
                                    <th>#</th>
                                    <th>{t('Crm.name')}</th>
                                    <th>{t('Crm.skypeID')}</th>
                                    <th>{t('Common.leadori')}</th>
                                    <th>{t('Crm.leadCompleteness')}</th>
                                    <th>{t('Crm.dateAdded')}</th>
                                    <th>{t('Crm.editLead')}</th>
                                    <th>{t('Crm.addFollowup')}</th>
                                    <th>{t('Crm.nextFollowupDate')}</th>
                                    <th>{t('Crm.viewLead')}</th>
                                    <th>{'Business'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {follow_data}
                            </tbody>
                            </table>
                            :
                            <table className={`table`} >
                                <thead>
                                    <tr className="th" align="center">
                                        <td><h3 align="center">{t('Common.noRecent')}</h3></td>
                                    </tr>
                                </thead>
                            </table>
                        }
                        </div>
                    </div>
                </div>
            );
}
export default FollowUpRecentTable