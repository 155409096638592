import React from 'react';
import style from './invoice.module.scss';
import {Card,Row,Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Summary} from './';
import moment from 'moment';
import { IMG_URL } from '../../../configuration/apiconfig';

function PrintContent(props) {
    const {t} = useTranslation();
    return (
        <div id="print_area" >
            <Card className={style.panel}>
                <Card.Body className={style.panelBody}>
                    <div className={style.logoImg}>
                        {
                            props.companyInfo &&
                            // <img src={props.companyInfo.login_logo} alt=""/>
                            <img src={`${IMG_URL}logos/fav_1926000.jpg`} alt=""/>
                        }
                    </div>
                    <Row>
                        <Col xs={6}>
                            <h4>{props.companyInfo&&props.companyInfo.company_name }</h4>
                            <p>{props.companyInfo&&props.companyInfo.company_address }</p>
                            <p>
                                <span>{t('Common.phone')} : </span> <span>{props.companyInfo&&props.companyInfo.phone }</span><br/>
                                <span>{t('profile.email')} : </span> <span>{props.companyInfo&&props.companyInfo.email }</span>
                            </p>
                        </Col>
                        <Col xs={6} className={`text-right`}>
                            <p>#{props.invoice_no && props.invoice_no}</p>
                            <h5>{props.order_date && moment(props.order_date).format("D MMM, YYYY")}</h5>
                        </Col>
                    </Row>
                    {/* <h4></h4> */}
                    <Row>
                        <Col sm={6}>
                            <h4>{t('Common.company')}</h4>
                            <div className={`${style.well}`}>
                                <address>
                                    <strong>{props.companyInfo&&props.companyInfo.company_name }</strong><br/>
                                    <span>{props.companyInfo&&props.companyInfo.company_address }</span><br/>
                                    <abbr title="Phone">{t('Common.phone')}:</abbr>{props.companyInfo&&props.companyInfo.phone }
                                </address>
                                <address>
                                    <strong>{t('profile.email')}</strong><br/>
                                    <span>{props.companyInfo&&props.companyInfo.email }</span>
                                </address>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <h4>{t('Common.purchaseAddress')} :</h4>
                            <div className={`${style.well}`}>
                                <address>
                                    <strong>{props.address&&props.address.name}</strong><br/>
                                    <span>{props.address&&`
                                                        ${props.address.name && props.address.name} 
                                                        ${props.address.pin &&props.address.pin} 
                                                        ${props.address.town &&props.address.town}`}</span><br/>
                                    <abbr title="Phone">{t('Common.phone')}:</abbr>{props.address &&props.address.mobile}
                                </address>
                                <address style={{visibility : "hidden"}}>
                                    <strong>Emial</strong><br/>
                                    <span>NA</span>
                                </address>
                            </div>
                        </Col>
                    </Row>
                    {
                        props.product_details &&
                        <Summary
                            data={props.product_details}
                        />
                    }
                </Card.Body>
            </Card>
        </div>
    )
}

export default PrintContent
