import React from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import companyName from '../../../store/mobxStore/companyName';
import { withRouter } from 'react-router';
import  EditReplicaAndLeads  from '../../components/dashboard/EditReplicaAndLeads';
import PageTitle from '../../components/common/pageTitle';
import { COMPANY_NAME } from '../../../configuration/apiconfig';



function RepnLeadEditView({t}) {

     return (
            <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | Replica and lead edit</title>
            </Helmet>
             <PageTitle title="edit_rep_lead" buttonOn={false} />
             <EditReplicaAndLeads 
             />
             </div>
     )
}
export default withTranslation()(withRouter(RepnLeadEditView))
