import { React, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Links from './Links';
import style from './dashboard.module.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { IMG_URL, BASE } from '../../../configuration/apiconfig';

function ReplicaLinksNew(props) {
    const { t } = useTranslation()

    return (

        <>
            {props?.profile?.replica1 &&

                <div className={style.dashbrd_lead_replica_sec}>

                    <div className={style.dashboard_pannel_box}>
                        <div className={style.dashboard_lead_replica_head}>
                            {t('Dashboard.replicaLinks')}
                        </div>


                        {props.profile?.replica1 &&
                            <div className={style.yo1}>
                                {/* <div className={style.yo2}>{t('Dashboard.replicaLink')} 1</div> */}
                                <div className={style.yo2}>{t(`Dashboard.${props.profile?.replica1_title}`)}</div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.replica1.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.replica2 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}>{t(`Dashboard.${props.profile?.replica2_title}`)}</div>
                                <div className={style.yo3}>




                                    {

                                        props.profile?.replica2.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.replica3 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}>{t(`Dashboard.${props.profile?.replica3_title}`)}</div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.replica3.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.replica4 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}>{t(`Dashboard.${props.profile?.replica4_title}`)}</div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.replica4.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.replica5 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}>{t(`Dashboard.${props.profile?.replica5_title}`)}</div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.replica5.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.replica6 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}>{t(`Dashboard.${props.profile?.replica6_title}`)}</div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.replica6.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.replica7 &&
                            <div className={style.yo1}>
                                {/* <div className={style.yo2}>{t('Dashboard.replicaLink')} 7</div> */}
                                <div className={style.yo2}> {t(`Dashboard.${props.profile?.replica7_title}`)}</div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.replica7.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.replica8 &&
                            <div className={style.yo1}>
                                {/* <div className={style.yo2}>{t('Dashboard.replicaLink')} 7</div> */}
                                <div className={style.yo2}> {t(`Dashboard.${props.profile?.replica8_title}`)}</div>
                                <div className={style.yo3}>
                                    {

                                        props.profile?.replica8.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }



                    </div>

                </div>
            }

        </>

    )


}

export default ReplicaLinksNew