import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class RepLeadEditService{
    key =localStorage.getItem('apiKey')

    //Get Replica, Lead List and url
    async RepLeadList(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'member/green_link', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //Send Need to be redirected Url after Replica and Lead completed
    async SendRedirectedRepLead(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'member/update_green_link', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }, 
                body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


}