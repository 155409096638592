import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import style from './payment.module.scss';

function FreeJoin(props) {
    const { t } = useTranslation();
    return (
        console.log("The check loading props is ", props.checkloading),
        console.log("Disable status is",props.checkneedtodisable),
        <div className={style.MianContent}>
            <div>
                <pre className={`alert alert-info`}>{t('Common.clickFinish')}</pre>
                <div className={style.Buttons}>
                    {
                        props.prevButtonClick &&
                        <Button color="primary" onClick={props.prevButtonClick}  className={`btn-pill  ${style.PrevButton}`} style={{ marginRight: '20px' }} >
                            <i className="fa fa-chevron-left" />

                            {t('profile.prev')} &nbsp;
                        </Button>
                    }
                    <Button color="primary" className={`btn-pill ${style.NextButton}`}  onClick={props.doStuff} type="submit" style={{ marginRight: '20px' }}>
                        {t('Button.finish')} &nbsp;
                    </Button>

                    {

                        props.checkloading.loadhere === true ?
                            <div style={{ marginBottom:"15px"}}>
                                <i className="fa fa-refresh fa-spin"
                                    style={{ padding: "20px" }}
                                />
                                <h6>Please wait as it will take up to 60 seconds to create your account</h6>
                            </div> :
                            
                            <></>

                    }



                </div>
            </div>
        </div>
    )
}

export default FreeJoin
