import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
//components
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner'
import Content from '../../components/crm/Content';

//service
import CrmService from '../../../service/crm/Crm';
import { withRouter } from 'react-router';
import companyName from '../../../store/mobxStore/companyName';
import { useHistory } from 'react-router';
import { withTranslation } from 'react-i18next';



function Index({ t }) {
    const history = useHistory();
    const service = new CrmService();

    const [state, setState] = useState({
        seetheselectedleadproduct : "",
        loading: true,
        followup: [],
        tiles: [],
        modal: {
            show: false,
            data: {},
            id: ''
        }

    })
    useEffect(() => {
        followup()
        TilesData()
    }, [state.modal.show]);



    //  useEffect(() => { 

    //  },[state.followup]);

    async function followup() {
        await service.Followup().then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    followup: res.data,
                }));
            } else {
                history.push('/logout');
            }
        })


    }

    const goToBusiness = (idyo) => {

        history.push({
            pathname: `/addbusiness/${idyo}`,
        })
    }

    async function TilesData() {
        service.Tiles().then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    tiles: res.data,
                }));


            } else {
                if (res.error.code === 1002) {
                    history.push('/logout');
                } else if (res.error.code === 1057) {
                    history.push({
                        pathname: '/dashboard',
                        state: {
                            error: true,
                            message: 'permissionDinied'
                        }
                    })
                }
            }
        })

    }

    const showModal = (type, id, index = '') => {
        let data = {};
        let lead = state.followup[index].find(lead => lead.id === id);
        let seethestuff = state.followup[index].find(lead => lead.id === id);
        seethestuff = {
            sendtheLeadproduct :lead.selected_category === '' ? '' : lead.selected_category,
        }

        setState(prevState => ({
            ...prevState,
            seetheselectedleadproduct : seethestuff.sendtheLeadproduct
        }));

        console.log("Here are the leads boy ", lead)

        if (type === 'edit') {
            lead = {
                first_name: lead.first_name,
                last_name: lead.last_name,
                gender_status: lead.gender === "M" ? "Male" : "Female",
                email_id: lead.email_id === 'NA' ? "" : lead.email_id,
                skype_id: lead.skype_id === 'NA' ? '' : lead.skype_id,
                mobile_no: lead.mobile_no === 'NA' ? '' : lead.mobile_no,
                country: lead.country === 'NA' ? '' : lead.country, //not present
                states: lead.state === '0' ? 'States' : lead.state,
                // replica_types: lead.type === 'NA' ? '' : lead.type,
                selectedLeadOrigin: lead.lead_origin === 'NA' ? '' : lead.lead_origin,
                selectedLeadProduct: lead.selected_category === '' ? '' : lead.selected_category,
                city: lead.city === 'NA' ? '' : lead.city,
                street_address: lead.street_address === 'NA' ? '' : lead.street_address,
                zip_code: lead.zip_code === 'NA' ? '' : lead.zip_code,
                description: lead.description,
                followup_date: lead.followup_date,
                lead_status: lead.lead_status,
                interest_status: lead.interest_status
            }
            data = lead
        }
        if (type === 'addFollowup') {
            lead = {
                first_name: lead.first_name,
                last_name: lead.last_name,
            }
            data = lead
        }
        if (type === 'NextFollowup') {
            lead = {
                followup_date: lead.followup_date,
            }
            data = lead
        }
        setState(prevState => ({
            ...prevState,
            modal: {
                title: type,
                show: true,
                id: id,
                data: data
            }
        }))
    }

    const handleClose = () => {
        setState(prevState => ({
            ...prevState,
            modal: {
                title: '',
                show: false,
                id: '',
                data: ''
            }
        }))
    }
    const successCallback = () => {
        setState(prevState => ({
            ...prevState,
            modal: {
                show: false,
            },
            notify: {
                show: true,
                message: t('validation.nextFollowupDateUpdatedSuccessfully'),
                type: 'success'
            }
        }));
        // FollowUpTable();
    }

    if (state.loading) {
        return (

            <Spinner />
        )

    } else {


        return (

            <div className="h-100">
                <Helmet>
                    <title>{companyName.companyName} | {t('Sidemenu.crm')}</title>
                </Helmet>
                <PageTitle title="crm" buttonOn={false} />
                <Content
                    {...state}
                    seetheselectedleadproduct = {state.seetheselectedleadproduct}
                    seeBusiness={goToBusiness}
                    showModal={showModal}
                    handleClose={handleClose}
                    callback={successCallback}
                // followup={followup}
                />
            </div>

        )
    }

}
export default withTranslation()((withRouter(Index)));