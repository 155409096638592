import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';


export default class CrmService{
    key =localStorage.getItem('apiKey')
    // crm tiles 
    async Tiles(){
        const response = await fetch(BASE_URL+'crm/crmTile', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }
    async Followup(){
        const response = await fetch(BASE_URL+'crm/followUp',{
            method:'GET',
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();

    }
    async  ViewLead(params){
        const url = new URL(`${BASE_URL}crm/viewLeads`);
        url.search = new URLSearchParams(params).toString();
        const response = await fetch(url,{
            method:'GET',
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();

    }
    async AddLead(form_data){
        // const url = new URL(`${BASE_URL}crm/addLeads`);
        const response = await fetch(`${BASE_URL}crm/addLeads`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            },
            body: JSON.stringify(form_data)
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }
    async EditLead(edit_data,id){

        // const url = new URL(`${BASE_URL}crm/addLeads`);
        const response = await fetch(`${BASE_URL}crm/editLeads/${id}`, {     //url
            method: 'PUT',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            },
            body: JSON.stringify(edit_data)
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    } 
    async TimeLine(id){
        const response = await fetch(`${BASE_URL}crm/time_line?id=${id}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            }
        })
        return await response.json();
    }
    async AddFollowup(form_data)
    {
        const response = await fetch(`${BASE_URL}crm/addFollowup`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            },
            body: form_data
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    } 
    
    async AddNextFollowup(form_data)
    {
        const response = await fetch(`${BASE_URL}crm/addNextFollowup`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            },
            body: form_data
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    } 
    async  CrmGraph(){
        const url = new URL(`${BASE_URL}crm/graph`);
        const response = await fetch(url,{
            method:'GET',
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();

    }


    async Sendbusinessdata(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'crm/add_business', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }, 
                body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    async Updatebusinessdata(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'crm/update_business', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }, 
                body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    async Getbusinessdata(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'crm/get_business', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }, 
                body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
  
    async GetRelationdata(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'crm/get_relationship', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }, 
                body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }  


    async DeleteRelationdata(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'crm/delete_relationship', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }, 
                body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }  


    async AddRelationdata(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'crm/add_relationship', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }, 
                body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }



  
  


    


    
}