import { React, useState, useEffect } from 'react';
import style from './dashboard.module.scss';
import style2 from '../tools/tools.module.scss';
// import {FormHead,Buttons,FormsControl} from '../profile/index'
import FormsControl from './FormsControl';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IMG_URL } from '../../../configuration/apiconfig';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
// import RLSearchContent from './RLSearchContent';
import RepLeadEditService from '../../../service/editreplead/RepLeadEdit'

function EditReplicaAndLeadsNew() {
  const { t } = useTranslation()
  const history = useHistory();
  const service = new RepLeadEditService();

  const [state, setState] = useState({
    repleadurllist: [],


  })

  useEffect(() => {
    service.RepLeadList().then((res) => {
      if (res.status) {
        // console.log(res) 
        setState(prevState => ({
          ...prevState,
          repleadurllist: res.data
        }));
        // console.log(state.repleadurllist)
      }
    })


  }, []);


  //change fields
  const ChangeHandler = (e, key) => {
    const { name, value } = e.target;
    const newData = state.repleadurllist.map((items) => {

      // console.log("Changing name is ",name);
      // console.log("Changing value is ",value);
      console.log("Changing items is ", items)

      if (name === items.field_name) {
        return {
          ...items,
          value: value
        }
      }
      else {
        return {
          ...items
        }

      }
    })



    // console.log("iooiioio",newData)
    setState(prev => ({
      ...prev,
      repleadurllist: newData
    }))

  }



  const SubShit = () => {


    ///////////////////////////////////////Start
    // // console.log(state.repleadurllist)
    // const newUrls = state.repleadurllist.map((newdata) => {
    //   return [{
    //          code : newdata.code,
    //          value : newdata.value
    //         // state.formData[newdata.code] : newdata.value,        
    // }] 

    // // return [
    // //          newdata.code,
    // //          newdata.value
    // //         // state.formData[newdata.code] : newdata.value,        
    // // ]

    // })
    // console.log("New urls is ",newUrls)
    /////////////////////////////////End


    let formData = {}
    for (const [key] of Object.entries(state)) {

      if (['repleadurllist'].includes(key)) {
        state[key].map((items) => {

          // console.log("Zukasi ", items);

          // console.log(items.field_name)
          formData[items.field_name] = items.value
          return false
        })
      }
    }

    console.log("The formdata is ", formData)
    console.log("The Stringified json formdata is ", JSON.stringify(formData))



    service.SendRedirectedRepLead(JSON.stringify(formData)).then((res) => {
      if (res.status) {
        // alert("Data send successfully")
        history.push('/editreplicalead');
      }
    })


  }

  return (
    console.log("repleadurllist is as ", state.repleadurllist),
    <div>
      <div className={style.MianContent}>
        <div className={style.search}>

          {/* start */}

          {/* <FormHead 
                title='bankDetails' 
                // change={''} 
                // status={''}
                // show={}
            /> */}

          {state.repleadurllist.length === 0 ?

            <center><h4>User has no replica and lead capture links</h4></center>

            : 
            <Form>
            {
              state.repleadurllist.map((forms, index) => {

                // return (
                //   <>
                //   <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                //     <Form.Label>{forms.title}</Form.Label>
                //     <Form.Control type="text" value={forms.value} onChange = { (event) => {changeHandler(event)} }/>
                //   </Form.Group>

                //   </>

                // )




                return <FormsControl

                  key={index}
                  change={ChangeHandler}
                  //type={forms.type}
                  // status={props.isEditable}
                  data={forms}
                />
              })

            }

            {
              <Button onClick={SubShit}>{t('Button.update')}</Button>
            }

          </Form>


            }


          {/* end */}


        </div>
      </div>



    </div>
  )
}

export default EditReplicaAndLeadsNew