import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Links from './Links';
import style from './dashboard.module.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { IMG_URL, BASE } from '../../../configuration/apiconfig';


function LeadCaptureLinksNew(props) {
    const { t } = useTranslation()

    return (

        <>
            {props?.profile?.lead1 &&

                <div className={style.dashbrd_lead_replica_sec}>
                    <div className={style.dashboard_pannel_box}>
                        <div className={style.dashboard_lead_replica_head}>
                            {t('Dashboard.leadCapturelinks')}
                        </div>


                        {props.profile?.lead1 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}> {t(`Dashboard.${props.profile?.lead1_title}_lead`)}</div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.lead1.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.lead2 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}> {t(`Dashboard.${props.profile?.lead2_title}_lead`)} </div>
                                <div className={style.yo3}>




                                    {

                                        props.profile?.lead2.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.lead3 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}> {t(`Dashboard.${props.profile?.lead3_title}_lead`)} </div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.lead3.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.lead4 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}>{t(`Dashboard.${props.profile?.lead4_title}_lead`)}</div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.lead4.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.lead5 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}> {t(`Dashboard.${props.profile?.lead5_title}_lead`)} </div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.lead5.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.lead6 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}>{t(`Dashboard.${props.profile?.lead6_title}_lead`)} </div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.lead6.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                        {props.profile?.lead7 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}> {t(`Dashboard.${props.profile?.lead7_title}_lead`)}</div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.lead7.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }
                           {props.profile?.lead8 &&
                            <div className={style.yo1}>
                                <div className={style.yo2}> {t(`Dashboard.${props.profile?.lead8_title}_lead`)}</div>
                                <div className={style.yo3}>



                                    {

                                        props.profile?.lead8.map((link, index) => {

                                            return (
                                                <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />)

                                        })

                                    }




                                </div>




                            </div>
                        }


                    </div>
                </div>
            }
        </>

    )


}

export default LeadCaptureLinksNew