import React from 'react';
import { useState } from 'react';
import style from './network.module.scss';
// import { animated, useTransition } from 'react-spring';
import { usePopperTooltip } from 'react-popper-tooltip';
import { ToolTips } from './'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BASE } from '../../../configuration/apiconfig';
const AddComponents = (props) => {

    const project = useSelector(state => state.ProjectConfig);
    const history = useHistory();
    const addMember = () => {
        if (props.storeUrl) {
            window.location.href= `${props.storeUrl}&leg=${props.leg}&placement=${props.user_name}`;
            // window.open(
            //     `${props.storeUrl}&leg=${props.leg}&placement=${props.user_name}`
                
            // );
        } else {
            history.push(`/register/${props.user_name}/${props.leg}/true`)
        }
    }
    return (<td colSpan="2">
        <div>
            {
                project.mlmPlan === 'Unilevel' ?
                    [
                        (project.userName !== props.user_name) ?
                            <img
                                src={`${BASE}public_html/images/tree/add_disabled.png`}
                                alt=""
                                className={`${style.addButtonImg} ${style.marginWidth}`}
                                key={props.user_name}
                            // onClick={addMember}
                            /> :
                            <img
                                src={`${BASE}/public_html/images/tree/add.png`}
                                alt=""
                                className={`${style.addButtonImg} ${style.marginWidth}`}
                                onClick={addMember}
                                key={props.user_name}
                            />
                    ]
                    :
                    [
                        (props.disabled && props.leg === props.disabled) || props.disabled === 'both' ?
                            <img
                                src={`${BASE}/public_html/images/tree/add_disabled.png`}
                                alt=""
                                className={`${style.addButtonImg} ${project.mlmPlan !== 'Binary' && style.marginWidth}`}
                                // onClick={addMember}
                                key={props.user_name}
                            /> :
                            <img
                                src={`${BASE}/public_html/images/tree/add.png`}
                                alt=""
                                className={`${style.addButtonImg} ${project.mlmPlan !== 'Binary' && style.marginWidth}`}
                                onClick={addMember}
                                key={props.user_name}
                            />
                    ]
            }
        </div>
    </td>)
}

const   TreeNode = (props) => {
 

   

    const hasChildren = (children) => {
        return children && children.length
    };
    const [isExpand, setIsExpand] = useState();
    const [linesw, setLine] = useState(0);
    const [exapndeduser, setexapndeduser] = useState();
    const [rootLeg, setRootLeg] = useState('');
    const [transform, setTranform] = useState(false);
    const [level] = useState("3");
    const [margin, setMargin] = useState();
    const [lineWidth, setLineWidth] = useState(0);
    const [render, setRender] = useState(0);
    const [headnode,setHeadnode]= useState(props.headnode);

    const [width,setWidth]=useState(localStorage.getItem('width')?localStorage.getItem('width'):'')




    console.log(width)



    const project = useSelector(state => state.ProjectConfig);
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip();


    useEffect(() => {
        if (props.depth === "1") {
            setRootLeg(props.position)
        } else {
            setRootLeg(props.rootLeg)
        }
        //   },[props,level])
    }, []);

   










    //other than Binary recursive component
    const recursiveComponent = (data) => {
        let Components = '';
        Components = data.children.map((children, index) => {


            return <td className={style.nodeContainer} colSpan="2" key={index}>


                <TreeNode
                    {...children}
                    expand={props.expand}
                    closeExpand={closeExpand}
                    setSearchExpand={props.setSearchExpand}
                    expndLevel={data.expndLevel}
                    rootLeg={rootLeg}
                    depthLevel={props.depthLevel}
                    toolTipConfig={props.toolTipConfig}
                    callParent={props.callParent}
                    storeUrl={props.storeUrl}
                    filterUser={props.filterUser}
                    expanded={props.expanded}
                headnode={props.headnode}

                />
            </td>

        })


        return Components;

    }
    const BinaryrecursiveComponent = (data) => {
        let Components = '';
        if (data.children.length === 1) {
            if (data.children[0].position === 'L') {
                Components = <>
                    {
                        data.children.map((children, index) => {
                            return <td className={style.nodeContainer} colSpan="2" key={index}>
                                <TreeNode
                                    {...children}
                                    expand={props.expand}
                                    closeExpand={closeExpand}
                                    setSearchExpand={props.setSearchExpand}
                                    expndLevel={data.expndLevel}
                                    rootLeg={rootLeg}
                                    depthLevel={props.depthLevel}
                                    toolTipConfig={props.toolTipConfig}
                                    callParent={props.callParent}
                                    storeUrl={props.storeUrl}
                                    filterUser={props.filterUser}
                                    expanded={props.expanded}
                                    headnode={props.headnode}

                                />
                            </td>
                        })
                    }
                    <AddComponents
                        user_name={props.user_name}
                        leg='R'
                        disabled={props.disabled}
                        storeUrl={props.storeUrl}
                    />
                </>
            } else {
                Components = <>
                    <AddComponents
                        user_name={props.user_name}
                        leg='L'
                        disabled={props.disabled}
                        storeUrl={props.storeUrl}
                    />
                    {
                        data.children.map((children, index) => {

                            return <td className={style.nodeContainer} colSpan="2" key={index}>
                                <TreeNode
                                    {...children}
                                    expand={props.expand}
                                    closeExpand={closeExpand}
                                    setSearchExpand={props.setSearchExpand}
                                    expndLevel={data.expndLevel}
                                    rootLeg={rootLeg}
                                    depthLevel={props.depthLevel}
                                    toolTipConfig={props.toolTipConfig}
                                    callParent={props.callParent}
                                    storeUrl={props.storeUrl}
                                    filterUser={props.filterUser}
                                    expanded={props.expanded}
                                    headnode={props.headnode}


                                />
                            </td>
                        })
                    }
                </>
            }
        } else {
            Components = data.children.map((children, index) => {
                return <td className={style.nodeContainer} colSpan="2" key={index}>
                    <TreeNode
                        {...children}
                        expand={props.expand}
                        closeExpand={closeExpand}
                        setSearchExpand={props.setSearchExpand}
                        expndLevel={data.expndLevel}
                        rootLeg={rootLeg}
                        depthLevel={props.depthLevel}
                        toolTipConfig={props.toolTipConfig}
                        callParent={props.callParent}
                        storeUrl={props.storeUrl}
                        filterUser={props.filterUser}
                        expanded={props.expanded}
                        headnode={props.headnode}

                    />
                </td>
            })
        }
        return Components
    }


    //showexpand 
    const showExpand = (data) => {

console.log(props.headnode)
       


        setexapndeduser(data.user_id)
       // props.filterUser(data.user_name)

        props.expand(
            data,
            setIsExpand,
            props.user_id,
            props.depthLevel,
            props.user_name,
        );




    }
    let line = {
        line1: 0,
        line2: 0
    }
    const getReference = (ref, index) => {

        line[index] = ref.getBoundingClientRect().left;

        if (line.line1 > 0 && line.line2 > 0) {

            if (line.line1 > line.line2) {
                setTranform(true);
            }
            else {
                setTranform(false);
            }
            const temp = Math.abs(line.line1 - line.line2)
            setLineWidth(temp)
            line.line2 = 0
            line.line1 = 0
        }
        else {
            if (line.line1 < 0 || line.line2 < 0) {

                const temp = Math.abs(line.line1 - line.line2)
                setLineWidth(temp)
                setTranform(true)
                line.line2 = 0
                line.line1 = 0
            }
        }
    }
    //close expand 
    const closeExpand = () => {
        props.closeExpand(props.depthLevel)
        setIsExpand(false)

    }
    const colspan = () => {
        let colspan = 0;
        if (project.mlmPlan === 'Binary') {
            colspan = 4;
        } else if (project.mlmPlan === 'Unilevel') {
            colspan = props.children ? (props.children.length * 2) + 2 : 4
        }
        else if (project.mlmPlan === 'Matrix') {
            colspan = props.children ? (props.children.length * 2) + 2 : 4
        }
        else if (project.mlmPlan === 'Board') {
            colspan = props.children ? (props.children.length * 2) + 2 : 4
        }
        else if (project.mlmPlan === 'Stair_Step') {
           colspan = props.children ? (props.children.length * 2) + 2 : 4
        }

        else {
            colspan = props.children ? props.children.length * 2 : 4
        }
        return colspan;
    }
    return (

        
    
        <>

            {props.firstLevel && project.userName !== props.user_name &&

                <div className={`${style.upIcon} ${style.positionRelative}`}>
              
                   {props.expanded ?
                   <div>
                        <img
                        src={`${BASE}public_html/images/tree/up.png`}
                        alt=""
                        onClick={closeExpand}
                        style={{backgroundColor:'red'}}

                    />
                       </div>
                  
                   
                   :
                   <img
                   src={`${BASE}public_html/images/tree/up.png`}
                   alt=""
                   onClick={() => props.callParent(props.father_id)}
                

               />

}

                </div>




            }
            <table cellPadding={0} cellSpacing={0} className={`${style.mainTable} ${project.mlmPlan === 'Binary' && style.width50}`}>

                <tbody>
                    {
                        !isExpand &&




                        <tr className={style.rootNode} >
                            <td colSpan={colspan()}>

                                <div className={`${style.node} ${project.mlmPlan !== 'Binary' && style.treeNode}`}

                                >
                                    <img
                                        src={props.photo}
                                        alt=""
                                        className={style.profileImage}
                                        ref={setTriggerRef}
                                        onDoubleClick={() => { props.callParent(props.user_id) }}

                                    />
                                    {visible && (
                                        <div
                                            ref={setTooltipRef}
                                            {...getTooltipProps({ className: `tooltip-container ${style.tooltipContainer}` })}
                                        >
                                            <div {...getArrowProps({ className: ` ${style.arrow}` })} />
                                            <ToolTips
                                                config={props.toolTipConfig}
                                                {...props}
                                            />
                                        </div>
                                    )}
                                    <p className={style.dmeoName}>{props.user_name}</p>
                                    {
                                        props.depth === level &&
                                        <div>
                                            <img
                                                src={`${BASE}/backoffice/public_html/images/tree/down.png`}
                                                alt=""
                                                className={style.downIcon}
                                                onClick={() => { showExpand(props) }}
                                            />
                                        </div>
                                    }
                                </div>

                            </td>
                        </tr>
                    }
                    {
                        props.depth !== level ?
                            <>
                                <tr>
                                    <td colSpan={colspan()}>
                                        <div className={`${style.line} ${style.down}`}
                                        ></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`${style.line} ${style.left}`}>&nbsp;</td>
                                    {
                                        project.mlmPlan !== 'Binary' ?
                                            props.children && props.children.map((items, index) => {

                                                if (index < props.children.length - 1) {
                                                    return <React.Fragment key={index}>
                                                        <td className={`${style.line} ${style.right} ${style.top}`} >&nbsp;</td>
                                                        <td className={`${style.line} ${style.left} ${style.top}`}>&nbsp;</td>
                                                    </React.Fragment>
                                                }

                                                return true;
                                            })
                                            :
                                            <React.Fragment >
                                                <td className={`${style.line} ${style.right} ${style.top}`}>&nbsp;</td>
                                                <td className={`${style.line} ${style.left} ${style.top}`}>&nbsp;</td>
                                            </React.Fragment>
                                    }
                                    {
                                        hasChildren(props.children) && project.mlmPlan === 'Unilevel' &&
                                        <React.Fragment >
                                            <td className={`${style.line} ${style.right} ${style.top}`}>&nbsp;</td>
                                            <td className={`${style.line} ${style.left} ${style.top}`}>&nbsp;</td>
                                        </React.Fragment>
                                    }
                                    {
                                        hasChildren(props.children) && project.mlmPlan === 'Matrix' &&
                                        props.children && props.children.length < width &&
                                        <React.Fragment >
                                            <td className={`${style.line} ${style.right} ${style.top}`}>&nbsp;</td>
                                            <td className={`${style.line} ${style.left} ${style.top}`}>&nbsp;</td>
                                        </React.Fragment>
                                    }
                                    {
                                        hasChildren(props.children) && project.mlmPlan === 'Board' &&
                                        <React.Fragment >
                                            <td className={`${style.line} ${style.right} ${style.top}`}>&nbsp;</td>
                                            <td className={`${style.line} ${style.left} ${style.top}`}>&nbsp;</td>
                                        </React.Fragment>
                                    }
                                    {
                                        hasChildren(props.children) && project.mlmPlan === 'Stair_Step' &&
                                        <React.Fragment >
                                            <td className={`${style.line} ${style.right} ${style.top}`}>&nbsp;</td>
                                            <td className={`${style.line} ${style.left} ${style.top}`}>&nbsp;</td>
                                        </React.Fragment>
                                    }
                                    <td className={`${style.line} ${style.right} `}>&nbsp;</td>
                                </tr>
                                <tr>
                                    {
                                        hasChildren(props.children) ?
                                            [
                                                project.mlmPlan !== 'Binary' ?
                                                    <React.Fragment key={project.mlmPlan}>
                                                        {recursiveComponent(props)}
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment key={project.mlmPlan}>
                                                        {BinaryrecursiveComponent(props)}
                                                    </React.Fragment>
                                            ]
                                            :
                                            [
                                                project.mlmPlan !== 'Binary' ?



                                                    <AddComponents
                                                        key={project.mlmPlan}
                                                        user_name={props.user_name}
                                                        leg={1}
                                                        disabled={props.disabled}
                                                        storeUrl={props.storeUrl}
                                                    />


                                                    :
                                                    < React.Fragment key={props.user_id} >
                                                        <AddComponents
                                                            user_name={props.user_name}
                                                            leg='L'
                                                            disabled={props.disabled}
                                                            storeUrl={props.storeUrl}
                                                        />
                                                        <AddComponents
                                                            user_name={props.user_name}
                                                            leg='R'
                                                            disabled={props.disabled}
                                                            storeUrl={props.storeUrl}
                                                        />
                                                    </  React.Fragment>
                                            ]
                                    }
                                    {
                                        hasChildren(props.children) && project.mlmPlan === 'Unilevel' &&
                                        <AddComponents
                                            user_name={props.user_name}
                                            leg={props.children.length + 1}
                                            disabled={props.disabled}
                                            storeUrl={props.storeUrl}
                                        />
                                    }
                                    {
                                        hasChildren(props.children) && project.mlmPlan === 'Matrix' &&
                                        props.children && props.children.length > 0 && props.children.length < width &&


                                        <AddComponents
                                            user_name={props.user_name}
                                            leg={props.children.length + 1}
                                            disabled={props.disabled}
                                            storeUrl={props.storeUrl}
                                        />
                                    }
                                    {
                                        hasChildren(props.children) && project.mlmPlan === 'Board' &&
                                        <AddComponents
                                            user_name={props.user_name}
                                            leg={props.children.length + 1}
                                            disabled={props.disabled}
                                            storeUrl={props.storeUrl}
                                        />
                                    }
                                    {

                                        hasChildren(props.children) && project.mlmPlan === 'Stair_Step' &&


                                        // props.children && props.children.length<0 &&
                                        <AddComponents
                                            user_name={props.user_name}
                                            leg={props.children.length + 1}
                                            disabled={props.disabled}
                                            storeUrl={props.storeUrl}
                                        />
                                    }

                                </tr>
                            </> :
                            [
                                isExpand &&
                                <tr key={props.user_name}>

                                    <td colSpan="4">
                                        <div className={`${style.line} ${style.left} ${style.expand}`}
                                            ref={el => {
                                                if (!el) return;
                                                getReference(el, 'line1');

                                            }}
                                        ></div>
                                        {
                                            props.rootLeg === 'R' ?
                                                <div>
                                                    <div className={` ${style.right} ${style.top} ${style.expandRight}`}
                                                        style={{
                                                            width: lineWidth,

                                                        }}

                                                    ></div>
                                                </div>
                                                :
                                                <div>

                                                    <div className={` ${style.right} ${style.top} ${style.expandLeft}`}
                                                        style={{
                                                            width: lineWidth,
                                                            transform: transform ? `translateX(${100}%)` : 'none'

                                                        }}

                                                    ></div>
                                                </div>

                                        }
                                        <div className={`${style.line} ${style.left} ${style.expandDown} `}
                                            ref={el => {

                                                if (!el) return;
                                                getReference(el, 'line2');

                                            }}
                                            style={{
                                                width: lineWidth,
                                            }}
                                        >
                                        </div>

                                        <div className={`${style.upIcon}`}>
                                            <img
                                                src={`${BASE}/backoffice/public_html/images/tree/up.png`}
                                                alt=""
                                                onClick={closeExpand}
                                            />
                                        </div>

                                    </td>
                                </tr>
                            ]
                    }
                </tbody>
            </table>


        </>
    );
};

export default TreeNode;