import React, { useState, useEffect } from 'react';
import style from './profile.module.scss';
import { FormHead, FormsControl, Buttons } from './';
import ProfileService from '../../../service/profile';
import { useHistory } from 'react-router-dom';


function PaymentDetails(props) {
    const [data, setData] = useState(props.data);
    const [loading, setLoading] = useState(false);

    console.log(props.data)


    const history = useHistory();
    const changeHandler = (e, isReq) => {
        const { name, value } = e.target;
        setData([...data].map(object => {
            if (object.code === name) {
                return {
                    ...object,
                    value: value,
                    error: value.length === 0 && isReq ? name : ''
                }
            }
            else return object;
        }))
    }
    useEffect(() => {
        console.log("callled")
        console.log(props.data)
    }, [props.data])
    const cancelButtonClick = () => {
        setData(props.data)
        props.cancelbtn();
    }
    const onSubmit = () => {
        console.log("========onsubmit")
        let error = false
        let formdata = {};
        data.forEach(element => {
            console.log("ysyfsfy", element)
            if (element.error) {
                error = true
            } else {
                formdata = {
                    ...formdata,
                    [element.code]: element.value
                }


            }
        });
        if (!error) {
            const service = new ProfileService();
            console.log("formdata", formdata)
            service.UpdatePaymentDetails(formdata).then((res) => {
                console.log("fefefef", formdata)
                if (res.status) {
                    props.showNotify('success', 'success', 'profile.' + res.data.message)
                    props.cancelbtn();
                    props.updateSuccess(data, props.stateKey);
                } else {
                    if (res.error.code === 1002) {
                        history.push('/logou');
                    } else {
                        props.showNotify('danger', 'error', 'validation.checkValueYouHaveSubmitted')
                    }
                }

                // props.cancelbtn();
            })
        }
    }
    const changeDropDown = (key, id) => {
        setData([...data].map(object => {
            if (object.code === id.code) {
                return {
                    ...object,
                    value: key
                }
            }
            else return object;
        }))

    }
    const Createstripe = () => {
        const service = new ProfileService();
        setLoading(true)
        service.getstripeId().then((res) => {
            if (res.status) {
                setData([...data].map(object => {

                    if (object.code === 'stripeAccount') {
                        return {
                            ...object,
                            value: res.data.stripe_id
                        }

                    }
                    else return object;


                }))
                props.updateSuccess(data, props.stateKey);

                window.location.href = res.data.accountLinks.url;
                setLoading(false)
            } else {
                if (res.error.code == 'fill_bank_info_first') {
                    props.showNotify('danger', 'error', 'validation.' + res.error.code)
                }
                props.showNotifywithoutTranslator('danger', 'error', res.error.code)
            }
        })

    }
    return (
        <div className={style.PaymentDetails}>
            <FormHead
                title='paymentDetails'
                change={props.change}
                status={props.status}
                show={props.isEditable}
            />
            {
                data.map((forms, index) => {
                    console.log(forms)
                    return <FormsControl
                        key={index}
                        change={changeHandler}
                        status={props.isEditable}
                        data={forms}
                        dropdownChange={changeDropDown}
                    />
                })
            }

            {
                !props.isEditable &&
                <Buttons
                    cancel={cancelButtonClick}
                    submitHandler={onSubmit}
                />
            }

{/* 
            <div class="justify-content-center  container " style={{ border: '1px solid rgb(208, 204, 230)', padding: '10px', background: 'rgb(238, 237, 244)', marginTop: '10px' }}>
                <div className={style.stripe_desc}>
                    First update Bank information and then click on Link Stripe.
                </div>
                <div className={style.stripe_id}>
                    <h6>STRIPE ACCOUNT ID - {props.data && props.data[0].value} </h6>
                </div>
                <button type="submit" id="screen" title='Link to stripe' class="btn btn-primary" onClick={Createstripe}>  {loading ?
                    <i className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                    /> : ''} Link to stripe </button>

            </div> */}




        </div>
    )
}

export default PaymentDetails
